
.tiles {
    display: flex;
}

.crop {
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin: 5px;
}

.crop:hover {
    margin: 0;
    border: 5px solid orange;
    border-radius: 10px;
}

.active {
    margin: 0;
    border: 5px solid red;
    border-radius: 10px;
}

.crop img {
    width: 1000px;
    height: 600px;
}

.fragments {
    margin: -80px 0 0 -120px;
}
.bush {
    margin: -80px 0 0 -240px;
}
.wall {
    margin: -80px 0 0 0px;
}
.wall-cracks {
    margin: -80px 0 0 -40px;
}
.wall-damaged {
    margin: -80px 0 0 -80px;
}
.stone {
    margin: -80px 0 0 -160px;
}
.red-base {
    margin: -40px 0 0 -120px;
}
.blue-base {
    margin: -40px 0 0 -80px;
}
