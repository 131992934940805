.custom-maps {
    margin-top: 10vh;
    text-align: center;
}

.custom-element {
    border: 1px solid orange;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
}